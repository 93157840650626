<template>
	<div>
		<br/>
		<h1 class="main-title">Объёмы сборов</h1>
		<br/>
		<div class="top-menu">
			<v-autocomplete
				class="narrow autocomplete-select"
				v-model="selectedProjects"
				:items="projects"
				outlined
				dense
				hide-details
				light
				chips
				small-chips
				deletable-chips
				clearable
				multiple
				return-object
				item-text="name"
				item-value="name"
				label="Выберите проект"
				:filter="filterFunction"
			>
				<template v-slot:item="{ item }">
					<span><sup>{{ item.id }}</sup> {{ item.name }}</span>
				</template>
			</v-autocomplete>
			<v-select
				class="narrow"
				v-model="selects.serp.value"
				:items="selects.serp.items"
				outlined
				dense
				hide-details
				label="Полнота серпов"
			></v-select>
			<v-select
				class="narrow"
				v-model="selects.topOut.value"
				:items="selects.topOut.items"
				outlined
				dense
				hide-details
				label="Вылет из ТОП (OUT)"
			></v-select>
			<v-select
				class="narrow"
				v-model="selects.ws.value"
				:items="selects.ws.items"
				outlined
				dense
				hide-details
				label="Полнота частотности"
			></v-select>
			<v-menu
				v-model="menu"
				:close-on-content-click="false"
				:nudge-right="40"
				transition="scale-transition"
				offset-y
				min-width="auto"
			>
				<template v-slot:activator="{ on, attrs }">
					<v-text-field
						class="narrow"
						v-model="filters.date"
						label="Выберите дату"
						prepend-icon="mdi-calendar"
						readonly
						outlined
						hide-details
						dense
						v-bind="attrs"
						v-on="on"
					></v-text-field>
				</template>
				<v-date-picker
					v-model="filters.date"
					locale="ru"
					first-day-of-week="1"
					no-title
					:max="new Date().toISOString().substr(0, 10)"
					@input="menu = false"
				></v-date-picker>
			</v-menu>
		</div>

		<p>
			<strong>Всего:</strong>
			{{ calcCount(selectedProjectType === 'full' ? fullTabItems : selectedProjectType === 'not_full' ? notFullTabItems : tabItems, 'count_query') }}
		</p>
		<p>
			<strong>Всего сохранено в CH: </strong>
			{{ calcCount(selectedProjectType === 'full' ? fullTabItems : selectedProjectType === 'not_full' ? notFullTabItems : tabItems, 'count_finish') }}
		</p>

		<v-select
			class="narrow"
			v-model="selects.tariff.value"
			:items="selects.tariff.items"
			outlined
			dense
			hide-details
			label="Тариф"
		></v-select>

		<v-tabs v-model="filters.tabId" grow>
			<v-tab v-for="item in tabs" :key="item.id">
				<span>{{ item.title }}</span>
			</v-tab>
		</v-tabs>
		<br/>

		<v-data-table
			class="projects-table"
			:headers="headers"
			:custom-sort="sortWithoutSummary"
			:items="filteredBySelects"
			:loading="loading.main || loading.topOut || loading.wsData"
			item-key="idx"
			:footer-props="{
				itemsPerPageOptions: [1000],
				itemsPerPageText: 'Строк на стр.'
			}"
			dense
			show-select
			v-model="selectedRows"
		>
			<template v-slot:item.name="{ item }">
				<td @click="navigateTo(item.project_id)">
					<span><sup>{{ item.project_id }}</sup> {{ findProject(item.project_id) || item.name }}</span>
				</td>
			</template>
			<template v-slot:item.count_query="{ item }">
				<td @click="navigateTo(item.project_id)">
					<span v-if="item.count_query !== undefined && item.count_query !== null">
						{{ item.count_query.toLocaleString() }}
					</span>
					<span v-else>-</span>
				</td>
			</template>
			<template v-slot:item.count_finish="{ item }">
				<td @click="navigateTo(item.project_id)">
					<span
						:class="{'red--text': !item.count_finish_prc}"
						v-if="item.count_finish !== undefined && item.count_finish !== null"
					>
						{{ item.count_finish.toLocaleString() }}
					</span>
					<span v-else>-</span>
				</td>
			</template>
			<template v-slot:item.count_finish_prc="{ item }">
				<td class="d-flex align-center" @click="navigateTo(item.project_id)">
					<div class="projects-table__count-finish-prc">
						<strong
							v-if="item.count_finish_prc !== undefined && item.count_finish_prc !== null"
							:class="{'red--text': item.count_finish_prc !== 100}"
						>
							{{ item.count_finish_prc.toLocaleString({ maximumFractionDigits: 3 }) }}%
						</strong>
						<span v-else>-</span>
					</div>
					<div v-show="item.project_id">
						<v-resave-queries-button
							:projectId="+item.project_id"
							:searchSystem="urlFilters.search_system"
							:date="urlFilters.date"
							all
						/>
					</div>
				</td>
			</template>
			<template v-slot:item.count_out="{ item }">
				<td @click="navigateTo(item.project_id)">
					<span v-if="typeof item.count_out === 'number'">{{ item.count_out.toLocaleString() }}</span>
					<span v-else>-</span>
				</td>
			</template>
			<template v-slot:item.count_out_prc="{ item }">
				<td @click="navigateTo(item.project_id)">
					<strong
						v-if="item.count_out_prc !== undefined"
						:class="{'red--text': item && item.count_out_prc > 0}"
					>
						{{ item.count_out_prc.toLocaleString(undefined, { maximumFractionDigits: 5 }) }}%
					</strong>
					<span v-else>-</span>
				</td>
			</template>
			<template v-slot:item.ws_serp_count_finish="{ item }">
				<td @click="navigateTo(item.project_id)">
					<span
						v-if="item.ws_serp_count_finish !== undefined && item.ws_serp_count_finish !== null"
					>
						{{ item.ws_serp_count_finish.toLocaleString() }}
					</span>
					<span v-else>-</span>
				</td>
			</template>
			<template v-slot:item.ws_serp_count_diff="{ item }">
				<td @click="navigateTo(item.project_id)">
					<strong
						v-if="item.ws_serp_count_diff !== undefined && item.ws_serp_count_diff !== null"
						:class="{'red--text': item.ws_serp_count_diff != 100}"
					>
						{{ item.ws_serp_count_diff.toLocaleString({ maximumFractionDigits: 3 }) }}%
					</strong>
					<span v-else>-</span>
				</td>
			</template>
			<template #item.count_incomplete_top15="{ item }">
				<span>{{ typeof item.count_incomplete_top15 === 'number' ? item.count_incomplete_top15.toLocaleString() : '-' }}</span>
			</template>
			<template #item.count_incomplete_top25="{ item }">
				<span>{{ typeof item.count_incomplete_top25 === 'number' ? item.count_incomplete_top25.toLocaleString() : '-' }}</span>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import resaveQueriesButton from './components/resaveQueriesButton.vue'

export default {
	metaInfo () {
		return {
			title: this.title
		}
	},

	components: {
		'v-resave-queries-button': resaveQueriesButton
	},

	data: () => ({
		filters: {
			date: null,
			tabId: 0
		},
		menu: false,
		title: 'Проекты',
		tabs: [
			{
				id: 0,
				title: 'yandex'
			},
			{
				id: 1,
				title: 'google'
			}
		],
		headers: [
			{
				text: 'Проект',
				value: 'name',
				sortable: false
			},
			{
				text: 'Всего',
				value: 'count_query',
				align: 'center',
				sortable: true
			},
			{
				text: 'Сохранено в CH',
				value: 'count_finish',
				align: 'center',
				sortable: true
			},
			{
				text: '% cохранено в CH',
				value: 'count_finish_prc',
				align: 'center',
				sortable: true
			},
			{
				text: 'Запросы в OUT',
				value: 'count_out',
				align: 'center',
				sortable: true
			},
			{
				text: '% запросов в OUT',
				value: 'count_out_prc',
				align: 'center',
				sortable: true
			},
			{
				text: 'Недослепки топ-15',
				value: 'count_incomplete_top15',
				align: 'center',
				sortable: true
			},
			{
				text: 'Недослепки топ-25',
				value: 'count_incomplete_top25',
				align: 'center',
				sortable: true
			},
			{
				text: 'Запросы с WS (CH)',
				value: 'ws_serp_count_finish',
				align: 'center',
				sortable: true
			},
			{
				text: '% запросов с WS (CH)',
				value: 'ws_serp_count_diff',
				align: 'center',
				sortable: true
			}
		],
		selects: {
			serp: {
				value: 'all',
				items: [
					{
						value: 'all',
						text: 'Все'
					},
					{
						value: 'serp_full',
						text: 'Full'
					},
					{
						value: 'serp_not_full',
						text: 'Not Full'
					}
				]
			},
			topOut: {
				value: 'all',
				items: [
					{
						value: 'all',
						text: 'Все'
					},
					{
						value: 'top_out',
						text: 'Top Out'
					},
					{
						value: 'top_out_10',
						text: 'Top Out (>10)'
					}
				]
			},
			ws: {
				value: 'all',
				items: [
					{
						value: 'all',
						text: 'Все'
					},
					{
						value: 'ws_full',
						text: 'Full'
					},
					{
						value: 'ws_not_full',
						text: 'Not Full'
					}
				]
			},
			tariff: {
				value: 'all',
				items: [
					{
						value: 'all',
						text: 'Все'
					},
					{
						value: 'base',
						text: 'Base'
					},
					{
						value: 'pro',
						text: 'Pro'
					},
					{
						value: 'premium',
						text: 'Premuim'
					},
					{
						value: 'special',
						text: 'Специальный'
					},
					{
						value: 'not_selected',
						text: 'Не выбран'
					}
				]
			}

		},
		selectedRows: [],
		selectedProjects: [],
		selectedProjectType: 'all',
		loading: {
			main: false,
			topOut: false,
			wsData: false
		}
	}),

	computed: {
		...mapGetters({
			projects: 'save/projects',
			analytics: 'save/analytics',
			wsDataByTab: 'save/wsDataByTab'
		}),
		...mapState({
			topOutProjects: state => state.save.topOutProjects
		}),
		urlFilters () {
			const query = { ...this.$route.query }
			const temp = query.project_id ? query.project_id : undefined
			if (temp) {
				query.project_ids = temp
			}
			delete query.project_id
			return query
		},
		selectedProjectIds () {
			return this.selectedProjects.length ? this.selectedProjects.map((el) => el.id) : undefined
		},
		currentTab () {
			return this.tabs.find((el) => el.id === this.filters.tabId).title
		},
		wsSerpByTab () {
			return this.wsDataByTab(this.currentTab)
		},
		tabItems () {
			return this.analytics(this.currentTab).map((el, idx) => {
				const elId = el.project_id
				let topOutData = null
				let wsData = null
				let tariff = null

				if (this.topOutProjects.length) {
					topOutData = this.topOutProjects.find((el) => el.project_id === elId)
				}

				if (this.wsSerpByTab && this.wsSerpByTab.length) {
					wsData = this.wsSerpByTab.find((el) => el.project_id === elId)
				}

				this.projects.forEach((project) => {
					if (project.id === elId) {
						tariff = project.tarif
					}
				})

				return {
					project_id: el.project_id,
					count_query: +el.count_query,
					count_finish: +el.count_finish,
					count_finish_prc: this.calcPercent(el.count_query, el.count_finish),
					name: topOutData ? topOutData.project_name : wsData ? wsData.project_name : undefined,
					count_out_prc: this.getCountOutPrc(el),
					count_out: el.count_out,
					ws_serp_count_finish: wsData && (wsData.ws_serp_count_finish || wsData.ws_serp_count_finish === 0) ? +wsData.ws_serp_count_finish : undefined,
					ws_serp_count_diff: wsData && (wsData.ws_serp_count_diff || wsData.ws_serp_count_diff === 0) ? +wsData.ws_serp_count_diff : undefined,
					idx: idx,
					tariff,
					count_incomplete_top15: el.count_incomplete_top15,
					count_incomplete_top25: el.count_incomplete_top25
				}
			})
		},
		filteredBySelects () {
			let items = [...this.tabItems]

			const outIds = this.topOutProjects ? this.topOutProjects.filter(this.outFilterFunction).map((item) => item.project_id) : []

			const keys = ['serp', 'topOut', 'ws', 'tariff']

			for (const key of keys) {
				const valueExists = this.selects[key].items.find((item) => item.value === this.selects[key].value)
				if (valueExists) {
					switch (this.selects[key].value) {
					case 'ws_full':
						items = items.filter(item => item.ws_serp_count_diff !== undefined && item.ws_serp_count_diff == 100)
						break
					case 'ws_not_full':
						items = items.filter(item => item.ws_serp_count_diff !== undefined && item.ws_serp_count_diff != 100)
						break
					case 'serp_full':
						items = items.filter(item => this.calcPercent(item.count_finish, item.count_query) === 100)
						break
					case 'serp_not_full':
						items = items.filter(item => this.calcPercent(item.count_finish, item.count_query) !== 100)
						break
					case 'top_out':
					case 'top_out_10':
						items = outIds.length ? items.filter((item) => outIds.includes(item.project_id)) : items
						break
					case 'base':
						items = items.filter(item => item.tariff === 'Base')
						break
					case 'pro':
						items = items.filter(item => item.tariff === 'Pro')
						break
					case 'premium':
						items = items.filter(item => item.tariff === 'Premium')
						break
					case 'special':
						items = items.filter(item => item.tariff === 'Специальный')
						break
					case 'not_selected':
						items = items.filter(item => item.tariff === 'Не выбран')
						break
					case 'all':
						break
					}
				}
			}

			const fieldsToCalculate = [
				'count_query',
				'count_finish',
				'count_finish_prc',
				'count_out',
				'count_out_prc',
				'ws_serp_count_finish',
				'ws_serp_count_diff',
				'count_incomplete_top15',
				'count_incomplete_top25'
			]

			let summary = items.reduce((acc, item) => {
				for (const field of fieldsToCalculate) {
					if (!isNaN(+item[field])) {
						if (Object.prototype.hasOwnProperty.call(acc, field)) {
							acc[field] += +item[field]
						} else {
							acc[field] = +item[field]
						}
					}
				}
				return acc
			}, {})

			summary = {
				...summary,
				name: 'Итого',
				isSummary: true,
				count_finish_prc: !isNaN(summary.count_finish_prc) ? summary.count_finish_prc / items.length : undefined,
				count_out_prc: !isNaN(summary.count_out_prc) ? summary.count_out_prc / items.length : undefined,
				ws_serp_count_diff: !isNaN(summary.ws_serp_count_diff) ? summary.ws_serp_count_diff / items.length : undefined
			}

			items.unshift(summary)
			return items
		},
		shouldUpdateAnalytics () {
			return `${this.urlFilters.date}|${this.urlFilters.project_ids ? this.urlFilters.project_ids : ''}`
		},
		shouldUpdateTopOut () {
			return `${this.urlFilters.date}|${this.urlFilters.search_system}`
		},
		outFilterFunction () {
			if (this.selects.topOut.value === 'top_out_10') {
				return (el) => el.count_out_prc !== 0 && el.count_out_prc >= 10
			} else if (this.selectedProjectType === 'top_out') {
				return (el) => el.count_out_prc !== 0
			}
			return () => {}
		}
	},

	watch: {
		shouldUpdateAnalytics: {
			handler: async function (val) {
				const [date, projectIDs] = val.split('|')
				if (date !== 'undefined') {
					try {
						this.loading.main = true
						await this.getAnalytics({
							date: date,
							project_ids: projectIDs ? Array.from(projectIDs.split(',')) : undefined
						})
					} catch (err) {
						console.log(err)
						this.loading.main = false
					} finally {
						this.loading.main = false
					}
				}
			},
			immediate: true
		},
		shouldUpdateTopOut: {
			handler: async function (val) {
				const [date, searchSystem] = val.split('|')
				if (date !== 'undefined' && searchSystem !== 'undefined') {
					try {
						this.loading.topOut = true
						this.$store.commit('save/clearTopOutProjects')
						await this.getTopOutProjects({
							top: 10,
							date: date,
							search_system: searchSystem
						})
					} catch (err) {
						console.log(err)
					} finally {
						this.loading.topOut = false
					}
				}
			},
			immediate: true
		},
		filters: {
			deep: true,
			async handler (val, oldVal) {
				this.updateUrl({
					date: val.date,
					search_system: this.currentTab
				})

				if ((!this.projects.length) || val.date !== oldVal.date) {
					try {
						await this.getProjects({ date: val.date })
					} catch (err) {
						console.log(err)
					}
				}
			}
		},
		'filters.date': {
			immediate: true,
			async handler (val) {
				if (val) {
					try {
						this.loading.wsData = true
						this.$store.commit('save/clearWsData')
						await this.getWsData({
							date: val
						})
					} catch (err) {
						console.log(err)
						this.loading.wsData = false
					} finally {
						this.loading.wsData = false
					}
				}
			}
		},

		selectedProjectIds: {
			handler: function (val) {
				this.updateUrl({
					project_id: val
				})
			},
			immediate: false
		},

		projects: {
			handler: async function (val, oldVal) {
				if (val !== oldVal) {
					this.selectedProjects = []
				}

				if (val && val.length && !this.selectedProjects.length) {
					if (this.urlFilters.project_ids && typeof this.urlFilters.project_ids !== 'string') {
						const arr = []
						this.urlFilters.project_ids.map((projectId) => {
							const selectedProject = val.find((el) => +el.id === +projectId)
							if (selectedProject) {
								arr.push(selectedProject)
							}
						})
						this.selectedProjects = arr
					} else if (typeof this.urlFilters.project_ids === 'string') {
						this.selectedProjects.push(val.find((el) => +el.id === +this.urlFilters.project_ids))
					}
				}
			},
			immediate: true
		}
	},

	async created () {
		try {
			await this.setFilters()
		} catch (_) {
		}
	},

	methods: {
		...mapActions({
			getProjects: 'save/getProjects',
			getAnalytics: 'save/getAnalytics',
			getTopOutProjects: 'save/getTopOutProjects',
			getWsData: 'save/getWsSavedSerpData'
		}),
		/* Обновление URL */
		async updateUrl (query) {
			await this.$router.replace({
				query: {
					...this.$route.query,
					...query
				}
			}).catch(() => {})
		},
		/* Обновление фильтров */
		async setFilters () {
			if (this.urlFilters.date) {
				this.filters.date = new Date(this.urlFilters.date).toISOString().substr(0, 10)
			} else {
				this.filters.date = new Date().toISOString().substr(0, 10)
				this.urlFilters.date = this.filters.date
			}

			this.filters.tabId = this.urlFilters.search_system ? this.tabs.find((el) => el.title === this.urlFilters.search_system).id : 0
		},

		findProject (id) {
			if (this.projects && Array.isArray(this.projects)) {
				const project = this.projects.find((el) => +el.id === +id)
				return project && project.name ? project.name : undefined
			}
			return undefined
		},

		calcPercent (query, finish) {
			const num = (+finish / +query) * 100
			return Number.isFinite(num) ? num : null
		},

		filterFunction (item, queryText, itemText) {
			return itemText.toLocaleLowerCase().indexOf(queryText.trim().toLocaleLowerCase()) > -1 || item.id.toString().indexOf(queryText.trim().toString()) > -1
		},

		calcCount (items, entity) {
			const count = items.map((el) => +el[entity]).reduce((partialSum, a) => partialSum + a, 0)
			return count
		},
		navigateTo (id) {
			this.$router.push(`/data/projects/save/not-saved-ch/${id}?date=${this.urlFilters.date}&search_system=${this.urlFilters.search_system}`)
		},
		sortWithoutSummary (items, field, isDesc) {
			const summary = items.find(item => item.isSummary)
			const sortedItems = items
				.filter(item => !item.isSummary)
				.sort((a, b) => {
					const sortA = +a[field[0]]
					const sortB = +b[field[0]]

					if (isNaN(sortA)) return 1
					if (isNaN(sortB)) return -1
					if (sortA < sortB) return isDesc[0] ? 1 : -1
					if (sortA > sortB) return isDesc[0] ? -1 : 1
					return 0
				})

			if (summary) {
				sortedItems.unshift(summary)
			}

			return sortedItems
		},

		getCountOutPrc (data) {
			if (data) {
				const {
					count_query,
					count_out
				} = data

				if (typeof count_query === 'number' && typeof count_out === 'number' && count_query !== 0) {
					return count_out * 100 / count_query
				}
			}

			return undefined
		}
	}
}
</script>

<style>
.top-menu {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin: 15px 0px;
}

.narrow {
	max-width: 300px;
	font-size: 12px;
}

.narrow:not(:last-child) {
	margin-right: 10px;
}

.v-select.autocomplete-select.v-input--dense .v-chip {
	margin-top: 4px;
}

.v-select.autocomplete-select.v-input--dense .v-label {
	font-size: inherit;
}

.v-select.autocomplete-select.v-input--dense .v-chip:first-child {
	margin-top: 8px;
}

.v-select.autocomplete-select.v-input--dense .v-chip:last-child {
	margin-bottom: 8px;
}

.v-input.date-picker {
	max-width: 330px;
}

.v-data-table.projects-table > .v-data-table__wrapper > table > thead > tr > th {
	padding: 16px;
}

.v-data-table.projects-table tbody tr:first-child {
	font-weight: 500;
	background-color: #f1f7fd;
}

.v-data-table.projects-table tbody tr:first-child .v-data-table__checkbox {
	display: none;
}

.v-data-table.projects-table tr:hover {
	cursor: pointer;
}

.v-data-table.projects-table tr:active {
	opacity: 0.6;
}

.projects-table__count-finish-prc {
	width: 70px;
	text-align: center;
	margin-right: 10px;
}
</style>
