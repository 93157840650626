<template>
	<v-dialog v-model="showDialog" max-width="490px">
		<template v-slot:activator="{ on,attrs }">
			<v-btn
				v-bind="attrs"
				class="white--text"
				:small="!showText"
				v-on="on"
				color="primary"
				dark
				>
					<v-icon :left="showText">{{ !success ? 'mdi-reload' : 'mdi-check-circle-outline' }}</v-icon>
					{{ showText ? 'Пересобрать запросы' : ''}}
			</v-btn>
		</template>
		<v-card>
			<v-card-title class="justify-center">
				<span class="text-center">Вы уверены что хотите отправить запрос(ы) на пересбор?</span>
			</v-card-title>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="blue darken-1" text @click="confirm">Отправить</v-btn>
				<v-btn color="grey darken-1" text @click="closeDialog">Отмена</v-btn>
				<v-spacer></v-spacer>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapActions } from 'vuex'

export default {
	props: {
		query: {
			type: String,
			default: ''
		},
		all: {
			type: Boolean,
			default: false
		},
		projectId: {
			type: Number
		},
		searchSystem: {
			type: String
		},
		date: {
			type: String
		},
		showText: {
			type: Boolean,
			default: false
		}
	},

	data () {
		return {
			showDialog: false,
			success: false
		}
	},

	methods: {
		...mapActions({
			resaveQueriesCH: 'save/resaveQueriesCH'
		}),

		closeDialog () {
			this.showDialog = false
		},

		async confirm () {
			if ((this.all || this.query) && this.projectId && this.searchSystem && this.date) {
				try {
					await this.resaveQueriesCH({
						body: {
							project_id: this.projectId,
							queries: this.query ? [this.query] : undefined,
							source: this.searchSystem,
							date: this.date,
							force: new Date().getHours()
						},
						all: this.all
					}).then(() => {
						this.$notify({ type: 'success', title: 'Запросы(ы) успешно отправлен(ы)' })
						this.success = true
					})
				} catch (err) {
					this.$notify({ type: 'error', title: err.message })
				}
			}
			this.showDialog = false
		}
	}
}
</script>
